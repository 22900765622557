import React from 'react'
import { Helmet } from 'react-helmet'
import ComingSoonLogs from '../components/ComingSoonLogs'
import DevLogs from '../components/DevLogs'
import Layout from '../components/Layout'
import UpdateLogs from '../components/UpdateLogs'

function Logs() {
    //console.log(commingSoonLogs.allContentfulLog.edges);
    return (
        <>
        <Helmet title="Logs | MM-develops"/>
        <Layout>
            <section>
                <h1>DEV LOGS</h1>
                <h5 className="mt-0">Welcome to the blog section of this website. Have a look around!</h5>
                <div className="row">
                    <div className="half">
                        <h3 className="mb-0">COMING SOON</h3>
                        <h5 className="mt-0">Read about new projects and live vicarously the experience of having project proposals rejected</h5>
                        <ComingSoonLogs className="log"/>
                    </div>
                    <div className="half">
                        <h3 className="mb-0">UPDATES</h3>
                        <h5 className="mt-0">All about website maintenance, optimization and my baby steps in SEO</h5>
                        <UpdateLogs className="log"/>
                    </div>
                </div>
            </section>
            <section className="grey">
                <h3 className="mb-0">DEVELOPER STUFF</h3>
                <h5 className="mt-0"> My personal diary of challenges and helpful code snippets. If any of this is somehow usefull, you have my permission to copy paste into your own projects</h5>
                <DevLogs className="log"/>
            </section>
        </Layout>
        </>
    )
}

export default Logs